.parent {
  // width: 100%;
  // height: 100%;
  // position: absolute;
  border: 2px solid black;
  padding: 0px !important;
  margin: 0 auto;
  .templateDesign {
    position: absolute;
    img {
      width: 100%;
    }
    .pageNoLocation {
      display: flex;
      justify-content: space-between;
      padding: 15px;
      position: absolute;
      top: 0;
      width: 100%;
      font-size: 18px;
      font-weight: 500;
      color: rgb(103, 98, 98);
    }
  }

  .logoEnadu {
    // padding: 20px;
    position: absolute;
    left: 34%;
    top: 20px;
    img {
      width: 330px;
      height: 120px;
    }
  }
  .enaduEarLeft {
    position: absolute;
    background-color: #058adc4f;

    border: 2px solid rgb(96, 163, 251);
    width: 330px;
    height: 120px;
    left: 20px;
    top: 20px;
  }
  .enaduEarRight {
    position: absolute;
    background-color: #058adc4f;
    border: 2px solid rgb(96, 163, 251);
    width: 330px;
    height: 120px;
    right: 20px;
    top: 20px;
  }
  .redUnderLine {
    position: absolute;
    width: 1015px;
    height: 20px;
    background-color: red;
    top: 160px;
    left: 20px;
  }
  .redUnderLineLeftHalf {
    position: absolute;
    width: 540px;
    height: 30px;
    background-color: red;
    top: 180px;
    left: 20px;
  }
  .redUnderLineRightHalf {
    position: absolute;
    width: 540px;
    height: 30px;
    background-color: red;
    top: 180px;
    right: 20px;
  }
  .logoEnaduLeft {
    position: absolute;
    left: 20px;
    top: 20px;
    // img{
    // }
  }
  .logoEnaduRight {
    position: absolute;
    right: 20px;
    top: 20px;
    // img{
    // }
  }

  .noTempWrap {
    padding: 10px;
    .noTemplateDesign {
      // display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 10px;
      border-bottom: 2px solid lightgray;
      .locality {
        padding-left: 14px;
        font-size: 18px;
        font-weight: 500;
        .regn {
          padding-left: 10px;
          color: rgb(103, 98, 98);
        }
      }
      .imgDiv {
        padding-right: 150px;
        img {
          height: 20px;
          width: 200px;
        }
      }
      .pgN {
        padding-right: 20px;
        font-size: 18px;
        font-weight: 900;
      }
    }
  }
}

.child {
  width: 100px;
  height: 100px;
  position: absolute;
  background-color: rebeccapurple;
  /* top:50px;
    left: 50px; */

  /* top: 52px;
    left: 53px;
    right: 1036px;
    bottom: 248px; */
}
.child1 {
  width: 100px;
  height: 100px;
  position: absolute;
  background-color: green;
  top: 0;
  right: 0;
}

.delChildDiv {
  position: absolute;
  top: -10px;
  right: -5px;
}
.delChildDiv:hover {
  cursor: pointer;
}

.moveAdArrow {
  position: absolute;
  top: -6px;
  left: -5px;
}
.moverAdPageOpt {
  position: absolute;
  top: 15px;
  left: -13px;
  width: 80px;

  // .css-1nmdiq5-menu{
  //     //css class for select dropDown scroll
  // }
}

.submitAds {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  margin-bottom: 20px;
  button {
    background-color: #0b5ed7;
    &:hover {
      background-color: #05357e;
    }
  }
}

.noAdsPositioned {
  font-size: large;
  font-weight: 500;
  margin: 0 auto;
  text-align: center;
  border: 2px solid;
  border-color: darkgray;
  padding: 25px;
  border-radius: 10px;
}

.draggableCard {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  .dragCard-innerContent {
    line-height: normal;
    font-size: 11px;
    font-weight: 600;
    width: auto;
  }
}

.submitBgMsg {
  display: flex;
  justify-content: center;
  // padding-top: 20px;
  margin-bottom: 10px;
  .success {
    color: green;
    font-weight: 600;
  }
  .failure {
    color: red;
    font-weight: 600;
  }
}

.newsGridWrapper {
  // border: 1px solid grey;
  margin-bottom: 50px;
  margin-top: 35px;
  padding-left: 15px;
  padding-right: 15px;
  .topMenuWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: space-between;
    // align-items: center;
    margin-bottom: 30px;
    .dropAndBtn {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 20px 0;
      .dropWrap {
        .bookmark {
          .css-13cymwt-control,
          .css-t3ipsp-control,
          .css-1nmdiq5-menu {
            width: 150px;
          }
        }
        display: flex;
        align-items: center;
        margin: 0 40px 0 0;
        min-width: 210px;
        .dropTitle {
          padding-right: 10px;
          .paperNumberDrop {
            position: relative;
            .erorClassPaperNumber {
              position: absolute;
              left: 0px;
              font-size: 14px;
              color: red;
              font-weight: 600;
            }
          }
          // font-size: 12px;
          font-size: 20px;
          font-weight: 600;
        }
        .templateDrop {
          padding-right: 10px;
          input[type="text"] {
            width: 40px;
          }

          .react-datepicker-wrapper {
            display: flex;
            align-items: center;
            input[type="text"] {
              width: 120px;
            }
            svg {
              margin-top: 3px;
            }
          }
        }
      }
      .btnSubmit {
        // padding-right: 30px;
        button {
          font-size: 14px;
          font-weight: 600;
          padding: 8px 20px;
          background-color: #0b5ed7;
          &:hover {
            background-color: #05357e;
          }
        }
      }

      .pdfDownLoad {
        &:hover {
          cursor: pointer;
        }
      }
    }
    .spaceDiv {
      // font-size: 15px;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 20px;
    }
    .thumnailModule {
      width: -webkit-fill-available;
    }
  }

  .bookMarkOverPage {
    position: absolute;
    z-index: 10;
    display: block;

    min-width: 50%;
    top: 35%;
    left: 20%;
    color: yellow;
    border: solid;
    border-color: lightslategrey;
    outline: 7px solid lightslategrey;
    border-radius: 20px;
    text-align: center;
    padding: 0px 20px;
    transform: rotate(300deg);
    -webkit-transform: rotate(300deg);
    p {
      color: lightslategrey;
      font-size: 120px;
      font-weight: 600;
    }
  }
}






.sectionWrap {
  margin-bottom: 20px;
  border-radius: 5px;
  padding: 15px;

  // Styles for Ads Details section
  &.adsDetailsWrap {
    border: 2px solid #007bff; // Blue border
    background-color: #e7f1ff; // Light blue background

    .sectionTitle {
      color: #007bff; // Blue title color
      font-weight: bold;
      text-align: center; // Ensure the title is aligned to the left
      font-size: x-large;
    }
  }
   // Common styles for spaceDiv and dropAndBtn
   .spaceDiv {
    margin-bottom: 15px;
    padding: 10px;
    background-color: #ffffff; // White background for content
    border: 1px solid #ddd; // Light gray border
    border-radius: 4px;
  }

}
.contentDetailsWrap {
  border: 2px solid #28a745; // Green border
  background-color: #e8ffe8; // Light green background

  .sectionTitle {
    color: #28a745; // Green title color
    font-weight: bold;
    text-align: center; // Ensure the title is aligned to the left
    font-size: x-large;
  }
}

.summaryDetailsWrap {
  border: 2px solid #ff6102; // Green border
  background-color: #efd6c7; // Light green background

  .sectionTitle {
    color: #ff6102; // Green title color
    font-weight: bold;
    text-align: center; // Ensure the title is aligned to the left
    font-size: x-large;
  }
}














@media print {
  body {
    -webkit-print-color-adjust: exact; /* Chrome, Safari */
    print-color-adjust: exact; /* Firefox */
    background-color: transparent; /* Ensure background color is printed */
  }

  /* Include background colors for specific elements */
  .element-with-background {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
  .bookMarkOverPage {
    position: absolute;
    z-index: 10;
    display: block;

    min-width: 50%;
    top: 35%;
    left: 20%;
    color: yellow;
    border: solid;
    border-color: lightslategrey;
    outline: 7px solid lightslategrey;
    border-radius: 20px;
    text-align: center;
    padding: 0px 20px;
    transform: rotate(300deg);
    -webkit-transform: rotate(300deg);
    p {
      color: lightslategrey;
      font-size: 120px;
      font-weight: 600;
    }
  }
}
